import {pathOr, pipe} from 'ramda';

import {isTestSession} from '@renofi/analytics';
import {
  getCookie,
  JWT_KEY,
  REFRESH_TOKEN_KEY,
  setCookie,
} from '@renofi/utilities/src/cookies';
import {
  AUTH_TOKEN,
  REFRESH_TOKEN,
  STORAGE_PREFIX,
  Storage,
  getEngine,
  getImpersonationStorage,
} from '@renofi/utilities/src/storage';
import {getLocation} from '@renofi/utilities/src/window';
import {
  getAuthToken,
  isCloudflareJwt,
  isCloudflareSession,
} from '@renofi/utilities/src/cloudflare';
import jwtDecode from '@renofi/utilities/src/jwtDecode';

import getAuthByQueryString from './getAuthByQueryString';
import getCurrentAuthTokens from './getCurrentAuthTokens';

const getOriginalUserId = pathOr(null, ['decodedJwt', 'originalUserId']);

export const refreshPageWithPath = () => {
  const {origin, pathname} = getLocation();
  getLocation().replace([origin, pathname].join(''));
};

export default async () => {
  const qStringAuth = getAuthByQueryString();
  const isAuthInQueryString = Boolean(
    qStringAuth?.jwt && qStringAuth?.refreshToken,
  );
  const ifInitImpersonation = pipe(getOriginalUserId, Boolean)(qStringAuth);
  let storage;

  if (ifInitImpersonation) {
    storage = getImpersonationStorage();
    storage.setItem(AUTH_TOKEN, qStringAuth.jwt);
    storage.setItem(REFRESH_TOKEN, qStringAuth.refreshToken);

    refreshPageWithPath();
    return {storage};
  } else if (isCloudflareSession() && !isCloudflareJwt()) {
    const data = await getAuthToken(
      process.env.REACT_APP_GRAPHQL_PROXY_URL.replace('/graphql', '/auth'),
    );
    if (data?.jwt && data?.refreshToken) {
      setCookie(JWT_KEY, data?.jwt);
      setCookie(REFRESH_TOKEN_KEY, data?.refreshToken);

      return {
        storage: new Storage({prefix: STORAGE_PREFIX}),
        jwt: data?.jwt,
        refreshToken: data?.refreshToken,
      };
    }
  } else if (isAuthInQueryString) {
    setCookie(JWT_KEY, qStringAuth?.jwt);
    setCookie(REFRESH_TOKEN_KEY, qStringAuth?.refreshToken);
    refreshPageWithPath();
    return {storage: new Storage({prefix: STORAGE_PREFIX})};
  }
  const {isImpersonating, jwt, refreshToken} = getCurrentAuthTokens();
  const engine = getEngine(isImpersonating ? 'session' : 'local');

  return {
    storage: new Storage({engine, prefix: STORAGE_PREFIX}),
    jwt,
    refreshToken,
  };
};
