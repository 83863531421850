import queryString from 'query-string';

import {isDeployPreviewUrl} from './netlify';

export const LOGIN_URL = isDeployPreviewUrl()
  ? window.location.origin.replace(/blueprint|lenders|secure/i, 'login')
  : process.env.REACT_APP_LOGIN_FRONTEND_URL;

export default ({sessionExpired = false} = {}) => {
  const location = window.location;
  if (location.origin.includes('login')) {
    return false;
  }

  const redirectUrl = location.href;
  const currentQueryString = {
    ...queryString.parse(location.search || ''),
    redirectUrl,
  };

  if (sessionExpired) {
    currentQueryString.sessionExpired = true;
  }

  const qString = queryString.stringify(
    isDeployPreviewUrl()
      ? {...currentQueryString, includeJwt: true}
      : currentQueryString,
  );

  return location.replace(`${LOGIN_URL}?${qString}`);
};
