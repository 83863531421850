import {documentWithAllProps} from '../document';

import creditLiability from './creditLiability';

export default `
city
coborrowerOwned
county
currentOccupancyOther
documents {
  ${documentWithAllProps}
}
creditLiabilities {
  ${creditLiability}
}
documentIds
existingMortgageLoan
inspectPaymentsForUi
homeownerMonthlyInsurance
homeownersAssociationFee
homeownersAssociationFeesIncluded
homeownersAssociationFeesExist

id
intendedOccupancy
marketValue
monthlyProfitOrLoss
mortgageMonthlyPayments
monthlyProfitOrLossOverride
mortgageAccountNumber
mortgageBalance
mortgageBalanceSecondLien
mortgageBalanceThirdLien
mortgageCompany
mortgageCompanyOther
mortgageInterestRate
mortgageMonthlyInsurance
mortgageMonthlyPayment
mortgageStatementDate
otherMonthlyExpenses
primaryBorrowerOwned
propertyStatus
propertyKind
propertyMonthlyTaxes
propertyStructureType
propertyUseType
rentalIncome
state
streetAddressOne
streetAddressTwo
taskId
taxAndInsuranceIncluded
yearPurchased
zipCode
`;
