import {ApolloLink, HttpLink, Observable} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import {onError} from '@apollo/link-error';
import {any, pathOr, propEq} from 'ramda';

import {getJwt} from '@renofi/utilities/src/auth';
import logger from '@renofi/utilities/src/logger';
import {getLocation} from '@renofi/utilities/src/window';
import {
  JWT_KEY,
  REFRESH_TOKEN_KEY,
  setCookie,
} from '@renofi/utilities/src/cookies';
import logoutWithRedirect from '@renofi/utilities/src/logoutWithRedirect';
import badQueryErrorLink from '@renofi/utils/src/apollo/badQueryErrorLink';
import {dataDogLink} from '@renofi/api';
import {
  getAuthToken,
  isCloudflareSession,
} from '@renofi/utilities/src/cloudflare';
import {sendEvent} from '@renofi/analytics';

import parseGraphQLErrors from '../utils/parseGraphQLErrors';

import mockLink from './mockLink';

const UNAUTHENTICATED_ERROR = 'UNAUTHENTICATED_ERROR';
const hasAuthMessage = any(propEq('message', UNAUTHENTICATED_ERROR));

export default ({context, uri}) => {
  const {config, serviceAccountId, storage} = context;
  const errorReportingEnabled =
    process.env.REACT_APP_ENABLE_GRAPHQL_ERROR_REPORTING === 'true';
  logger.log('ENABLE_GRAPHQL_ERROR_REPORTING', errorReportingEnabled);

  const authLink = setContext((_, {headers, ...context}) => {
    const jwt = getJwt({storage});
    const lastActivity = storage.getItem('auth:action') || Date.now();

    return {
      ...context,
      headers: {
        ...headers,
        ...(jwt ? {Authorization: jwt} : {}),
        'X-Last-Activity': lastActivity,
        'X-Renofi-Origin': window?.location?.href,
        ...(serviceAccountId ? {'X-Service-Account-ID': serviceAccountId} : {}),
      },
    };
  });

  const errorLink = onError(({graphQLErrors, operation, forward}) => {
    const context = operation.getContext();
    const statusCode = pathOr(null, ['response', 'status'], context);
    const isAuthStatusError = statusCode === 401 || statusCode === 403;
    const isAuthErrorResponse = graphQLErrors && hasAuthMessage(graphQLErrors);
    const isAuthError = isAuthErrorResponse || isAuthStatusError;
    const location = getLocation();
    const isAtLogin = location.hostname.includes('login');

    if (isAuthError && !isAtLogin && isCloudflareSession()) {
      sendEvent('Blueprint/Session-Error', {
        isCloudflareSession: true,
      });

      return new Observable((observer) => {
        getAuthToken(
          process.env.REACT_APP_GRAPHQL_PROXY_URL.replace('/graphql', '/auth'),
        )
          .then((data) => {
            if (data?.jwt && data?.refreshToken) {
              setCookie(JWT_KEY, data?.jwt);
              setCookie(REFRESH_TOKEN_KEY, data?.refreshToken);

              const oldHeaders = operation.getContext().headers || {};
              operation.setContext({
                headers: {
                  ...oldHeaders,
                  authorization: data.jwt,
                },
              });
              // Retry operation with new token
              const subscriber = forward(operation).subscribe({
                next: (result) => {
                  observer.next(result);
                },
                error: (error) => {
                  observer.error(error);
                },
                complete: () => {
                  observer.complete();
                },
              });

              sendEvent('Blueprint/Session-Refresh-Success', {
                isCloudflareSession: true,
              });

              // Return cleanup function
              return () => {
                subscriber.unsubscribe();
              };
            }
          })
          .catch((error) => {
            sendEvent('Blueprint/Session-Refresh-Failed', {
              isCloudflareSession: true,
            });
            console.error('Token refresh failed:', error);
            storage.clear();
            logoutWithRedirect();
          });

        // Since we're handling the retry separately, just let the original error flow through
        return undefined;
      });
    }

    if (isAuthError && !isAtLogin && !isCloudflareSession()) {
      sendEvent('Blueprint/Session-Error', {
        isCloudflareSession: false,
      });
      storage.clear();
      logoutWithRedirect();
    }

    if (errorReportingEnabled && !isAuthError && graphQLErrors?.length) {
      parseGraphQLErrors({graphQLErrors, operation});
    }
  });

  const httpLink = new HttpLink({
    uri,
    fetch: (resource, init = {}) => {
      return fetch(resource, {...init, redirect: 'manual'}).then((response) => {
        if (response.status === 0 && response.type === 'opaqueredirect') {
          getLocation().reload();
        }

        if (response.status >= 490 && response.status <= 499) {
          return new Response(response.body, {...response, status: 200});
        }

        return response;
      });
    },
  });

  const responseLink = new ApolloLink((operation, forward) => {
    return forward(operation).map((result) => {
      const context = operation.getContext();
      const lastActivity = context.response.headers.get('X-Last-Activity');

      if (lastActivity) {
        storage.setItem('auth:action', lastActivity);
      }
      return result;
    });
  });

  return ApolloLink.from([
    ...mockLink(config),
    badQueryErrorLink,
    responseLink,
    dataDogLink,
    errorLink,
    authLink,
    httpLink,
  ]);
};
