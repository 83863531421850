import {gql} from '@apollo/client';

const ENUM_ENTRY = `{
  label
  value
}`;

export default gql`
  query enums {
    enums {
      documentTypes {
        group
        label
        value
        active
      }
      assetTypes ${ENUM_ENTRY}
      incomeTypes ${ENUM_ENTRY}
      interestRateTypes ${ENUM_ENTRY}
      opportunityTermsTypes ${ENUM_ENTRY}
      renovationUnderwritingRequestDocumentTypes ${ENUM_ENTRY}
      realEstateOwnedPropertyOccupancyTypes ${ENUM_ENTRY}
      realEstateOwnedPropertyStatusTypes ${ENUM_ENTRY}
      realEstateOwnedPropertyTypeTypes ${ENUM_ENTRY}
      underwritingConditionCompletionStages ${ENUM_ENTRY}
      underwritingConditionTypes ${ENUM_ENTRY}
      underwritingConditionResponsibleAgents ${ENUM_ENTRY}
    }
  }
`;
