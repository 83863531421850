import React, {useMemo} from 'react';

import PropTypes from 'prop-types';
import {propOr} from 'ramda';

import {Flex, TimeAgo} from '@renofi/components-internal';
import {successDark} from '@renofi/theme';
import {taskDocumentStatuses} from '@renofi/utilities/src/enums/taskDocumentEnums';
import {rejectionReasonTypes} from '@renofi/utilities/src/enumTypes';
import {formatDateTime} from '@renofi/utilities/src/dates';
import {getFullName} from '@renofi/utilities/src/data';

import useHoverCancel from '../../useHoverCancel';

import {
  DoubleCheckIcon,
  FileNameText,
  SubTitle,
  SubTitleText,
  Uploading,
} from './styled';

const ACTIONED_PROPS = {
  [taskDocumentStatuses.accepted]: 'acceptedBy',
  [taskDocumentStatuses.rejected]: 'rejectedBy',
  [taskDocumentStatuses.inReview]: 'addedBy',
  [taskDocumentStatuses.processingFile]: 'addedBy',
};

const DocumentTimestamp = ({
  addedPrefix = 'added',
  createdAt,
  fileName,
  internal,
  rejectionReason,
  rejectedAt,
  reviewedAt,
  rejectedPrefix = 'returned',
  reviewedPrefix = 'reviewed',
  status,
  ...props
}) => {
  const {hoverCancel} = useHoverCancel();

  const subTitle = useMemo(() => {
    switch (status) {
      case internal && taskDocumentStatuses.rejected:
        return `${rejectedPrefix} ${formatDateTime(rejectedAt)}`;
      case taskDocumentStatuses.rejected:
        return rejectionReasonTypes[rejectionReason] || 'File returned';
      case taskDocumentStatuses.accepted:
        return `${reviewedPrefix} ${formatDateTime(reviewedAt)}`;
      case taskDocumentStatuses.inReview:
      case taskDocumentStatuses.processingFile:
        return [
          addedPrefix,
          ' ',
          <TimeAgo key={createdAt} date={new Date(createdAt)} />,
        ];
      default:
        return null;
    }
  }, [internal, status]);

  const userName = useMemo(() => {
    if (!internal) {
      return '';
    }
    const user = propOr(null, ACTIONED_PROPS[status], props);

    return user ? ` by ${getFullName(user)}` : '';
  }, [internal, status]);

  if (status === taskDocumentStatuses.progress) {
    return (
      <Uploading>{hoverCancel ? 'Cancel uploading' : 'Uploading'}</Uploading>
    );
  }

  return (
    <Flex
      css={{position: 'relative', height: '100%'}}
      flexDirection="column"
      flexGrow={1}
      justifyContent="flex-end"
      pb={2}>
      <FileNameText>{fileName}</FileNameText>
      <SubTitle>
        {status === taskDocumentStatuses.accepted && (
          <DoubleCheckIcon color={successDark} />
        )}
        <SubTitleText status={status}>
          {subTitle}
          {userName}
        </SubTitleText>
      </SubTitle>
    </Flex>
  );
};

DocumentTimestamp.propTypes = {
  addedPrefix: PropTypes.string,
  createdAt: PropTypes.string,
  fileName: PropTypes.string,
  internal: PropTypes.bool,
  rejectedAt: PropTypes.string,
  rejectedPrefix: PropTypes.string,
  rejectionReason: PropTypes.string,
  reviewedAt: PropTypes.string,
  reviewedPrefix: PropTypes.string,
  status: PropTypes.string,
};

export default DocumentTimestamp;
