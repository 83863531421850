import React from 'react';

import {useHistory} from 'react-router-dom';

import {Drawer, Flex, Tooltip} from '@renofi/components-internal';
import {useScreenSize} from '@renofi/utils';

import Notes from './icons/Notes';
import Pipeline from './icons/Pipeline';
import Conditions from './icons/Conditions';
import LinkButton from './components/LinkButton';

const ICONS = {
  pipeline: Pipeline,
  conditions: Conditions,
  notes: Notes,
};

const TABS = [
  {label: 'Pipeline', value: 'pipeline'},
  {label: 'Conditions', value: 'conditions'},
  {label: 'Notes', value: 'notes'},
];

const LeftSideNavbar = ({active}) => {
  const history = useHistory();
  const {isMobile} = useScreenSize();

  function onClick(value) {
    if (value === 'pipeline') return history.push('/borrowers-pipeline');
    if (value === 'conditions') return history.push('/underwriting-conditions');
    if (value === 'notes') return history.push('/notes');
  }

  if (isMobile) return null;

  return (
    <Drawer
      contentCss={{overflow: 'auto'}}
      css={{zIndex: 2, marginRight: 0}}
      min={20}
      max={80}
      open
      whiteBg
      sticky
      show
      top={130}>
      <Flex flexDirection="column" p={3} css={{gap: 16}}>
        {TABS.map(({label, value}) => {
          const Icon = ICONS[value];

          return (
            <Tooltip
              elementCss={{width: 56}}
              tag="div"
              key={value}
              content={<div>{label}</div>}>
              <LinkButton
                active={active === value}
                tab={value}
                key={value}
                onClick={() => onClick(value)}>
                {() => <Icon />}
              </LinkButton>
            </Tooltip>
          );
        })}
      </Flex>
    </Drawer>
  );
};

export default LeftSideNavbar;
