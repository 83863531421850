const STREET_KEYS = [
  'streetAddress',
  'streetAddressOne',
  'streetAddressTwo',
  'propertyStreetAddress',
  'propertyStreetAddressOne',
  'propertyStreetAddressTwo',
];

const ADDRESS_KEYS = [
  'city',
  'state',
  'zipCode',
  'propertyCity',
  'propertyState',
  'propertyZipCode',
];

const joinByKeys = (keys = [], joinWith, data = {}) => {
  return keys
    .map((key) => data[key])
    .filter((value) => value !== null && value !== undefined)
    .join(joinWith);
};

const getFormattedAddress = (data = {}, joinWith = ', ') => {
  if (!data || Object.keys(data).length === 0) {
    return '';
  }

  const street = joinByKeys(STREET_KEYS, joinWith, data);
  const rest = joinByKeys(ADDRESS_KEYS, joinWith, data);

  return [street, rest].filter(Boolean).join(joinWith);
};

export const getMultiLineAddress = (data = {}) => {
  if (!data || Object.keys(data).length === 0) {
    return '';
  }

  const street = joinByKeys(STREET_KEYS, ', ', data);
  const rest = joinByKeys(ADDRESS_KEYS, ', ', data);

  return [street, rest].filter(Boolean).join('\n');
};

export default getFormattedAddress;
