import styled from '@emotion/styled';

import {basic55} from '@renofi/theme/src/colors';

import Flex from '../Flex';

export const Wrapper = styled(Flex)(
  {
    display: 'flex-block',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  ({small}) => ({
    fontSize: small ? 13 : 15,
    height: small ? 16 : 20,
  }),
);

export const Content = styled.label(
  {
    display: 'block',
    marginBottom: 0,
    flexGrow: 1,
  },
  ({theme}) => theme,
);

export const Help = styled.span(
  {
    color: basic55,
    lineHeight: '17px',
  },
  ({small}) => ({
    fontSize: small ? 12 : 14,
    fontStyle: small ? 'italic' : 'normal',
  }),
);
