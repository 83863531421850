import React, {Fragment, useContext, useState} from 'react';

import PropTypes from 'prop-types';
import {useParams} from 'react-router-dom';
import {either, isEmpty, isNil} from 'ramda';
import {GET_PROJECT_DETAILS, useUpdateMessage} from 'api';
import {ModalsContext, RejectedDocument} from 'modules/modals';
import MessageBorrower from 'modules/message';
import {concat, without} from 'lodash';

import {
  Checkbox,
  Box,
  DocumentViewer,
  Flex,
  Text,
} from '@renofi/components-internal';
import {useAcceptDocument, useEnums} from '@renofi/graphql';
import {
  TaskDocument,
  getAddedPrefixByDocumentType,
} from '@renofi/modules-internal';
import {useNotifications} from '@renofi/utilities/src/hooks';
import {basic80} from '@renofi/theme';

const SupportingDocuments = ({
  allowedDocumentTypes,
  entityId = null,
  canSelect = false,
  onChangeDocumentType,
  onClickDocument,
  documents = [],
  selectedDocumentsFooter: SelectedDocumentsFooter,
  showLabel = false,
  inline = false,
}) => {
  const {projectId} = useParams();
  const modals = useContext(ModalsContext);
  const {documentTypes} = useEnums();
  const {acceptDocument} = useAcceptDocument({
    refetchQueries: [
      {
        query: GET_PROJECT_DETAILS,
        variables: {id: projectId},
      },
    ],
  });
  const {addNotification} = useNotifications();
  const updateMessage = useUpdateMessage();
  const [docViewerDocument, setDocViewerDocument] = useState(null);
  const [selectedDocIds, setSelectedDocIds] = useState([]);

  const onAcceptDocument = async (documentId) => {
    await acceptDocument({variables: {id: documentId}});
  };

  const onClickTaskDocument = (doc) => {
    if (Boolean(onClickDocument)) {
      return onClickDocument(doc);
    }

    setDocViewerDocument(doc);
  };

  const onFileSelect = (documentId) => {
    setSelectedDocIds((state) =>
      state.includes(documentId)
        ? without(state, documentId)
        : concat(state, documentId),
    );
  };

  const onRejectDocument = (documentId) => {
    updateMessage({
      type: 'documents',
      active: true,
      reason: 'return',
      documents: [documentId],
    });
  };

  const onShowRejection = async (doc) => {
    if (either(isNil, isEmpty)(modals)) {
      return false;
    }

    modals.setModalProps({
      acceptLabel: 'Done',
      footerCss: {
        '> div': {
          justifyContent: 'space-between',
        },
      },
      rejectLabel: 'Cancel rejection',
      rejectVariant: 'danger',
      hasButtons: true,
      onAccept: () => modals.onClose(),
      onReject: async () => {
        modals.setLoading(true);
        await onAcceptDocument(doc?.id);
        await addNotification({
          type: 'snackbar',
          variant: 'success',
          content: 'Document rejection canceled.',
        });
        modals.onClose();
      },
      header: `Rejected document (${doc?.rejectionReason})`,
      componentProps: {document: doc},
      component: RejectedDocument,
    });
  };

  if (!documents.length) {
    return <Text>No supporting documents</Text>;
  }

  return (
    <Fragment>
      {documents.map((doc) => {
        const nickname = getAddedPrefixByDocumentType(doc);
        const addedPrefix = `${nickname} added`.trim();
        const reviewedPrefix = `${nickname} reviewed`.trim();
        const label = showLabel
          ? documentTypes.find(({value}) => value === doc.documentType)
          : false;

        return (
          <Box key={doc.id} my={inline ? 2 : 3}>
            {Boolean(label) ? (
              <Text
                color={basic80}
                fontFamily="Averta-Bold"
                fontSize={14}
                mb={2}>
                {label?.label}
              </Text>
            ) : null}

            <Flex alignItems="center" justifyContent="space-between">
              {Boolean(SelectedDocumentsFooter) && (
                <Box width={64}>
                  <Checkbox
                    css={{
                      position: 'relative',
                      left: 8,
                      top: 3,
                    }}
                    checked={selectedDocIds.includes(doc.id)}
                    onChange={() => onFileSelect(doc.id)}
                  />
                </Box>
              )}

              <TaskDocument
                addedPrefix={addedPrefix}
                allowedDocumentTypes={allowedDocumentTypes}
                canChangeDocumentType={Boolean(onChangeDocumentType)}
                key={doc.id}
                internal
                onAccept={onAcceptDocument}
                onChangeDocumentType={onChangeDocumentType}
                onClick={() => onClickTaskDocument(doc)}
                onReject={onRejectDocument}
                onShowRejection={() => onShowRejection(doc)}
                reviewedPrefix={reviewedPrefix}
                {...doc}
              />
            </Flex>
          </Box>
        );
      })}
      {Boolean(docViewerDocument) ? (
        <DocumentViewer
          hasControls
          showCloseButton
          documents={[docViewerDocument]}
          itemId={docViewerDocument.id}
          visible={!!docViewerDocument}
          onClose={() => setDocViewerDocument(null)}
          onPrev={setDocViewerDocument}
          onNext={setDocViewerDocument}
          onClick={() => setDocViewerDocument(null)}
        />
      ) : null}
      <MessageBorrower />
      {Boolean(
        canSelect && selectedDocIds?.length && SelectedDocumentsFooter,
      ) ? (
        <SelectedDocumentsFooter
          entityId={entityId}
          onClickCancel={() => setSelectedDocIds([])}
          selectedDocIds={selectedDocIds}
        />
      ) : null}
    </Fragment>
  );
};

SupportingDocuments.propTypes = {
  allowedDocumentTypes: PropTypes.arrayOf(PropTypes.string),
  canSelect: PropTypes.bool,
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      documentType: PropTypes.string,
    }),
  ),
  entityId: PropTypes.string,
  onChangeDocumentType: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  onFileSelect: PropTypes.func,
  selectedDocumentsFooter: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.func,
  ]),
  showLabel: PropTypes.bool,
  inline: PropTypes.bool,
};

export default SupportingDocuments;
