import styled from '@emotion/styled';

import {basic55, basic75, basic80} from '@renofi/theme/src/colors';

import Box from '../Box';
import Flex from '../Flex';
import Text from '../Text';

export const Wrapper = styled(Box)({
  width: '100%',
  position: 'relative',
});

export const Field = styled(Flex)(
  {
    width: '100%',
    alignItems: 'center',
    padding: '.5rem .75rem',
    minHeight: 44,
  },
  ({withLabel, small}) => ({
    marginTop: withLabel ? (small ? 2 : 6) : 0,
  }),
);

export const Input = styled.textarea(
  {
    width: '100%',
    lineHeight: '24px',
    border: 'none',
    backgroundColor: 'transparent',
    outline: 'none',
    padding: 0,
    margin: 0,
  },
  ({resizable, small, rows}) => ({
    height: rows * 16 + 12,
    fontSize: small ? 15 : 16,
    resize: resizable ? 'vertical' : 'none',
  }),
);

export const Fake = styled.div(
  {
    display: 'flex',
    width: '100%',
    fontSize: 16,
    lineHeight: '24px',
  },
  ({disabled, rows}) => ({
    color: disabled ? basic55 : basic80,
    minHeight: rows * 16 + 12,
  }),
);

export const Help = styled(Text)({
  color: basic55,
  fontSize: 14,
  lineHeight: '17px',
  fontStyle: 'italic',
});

export const Label = styled.label(
  {
    display: 'block',
    color: basic75,
    lineHeight: '17px',
    marginBottom: 0,
  },
  ({small}) => ({
    fontSize: small ? 13 : 14,
  }),
);
