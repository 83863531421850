import {gql} from '@apollo/client';

export default gql`
  query internalUsersSearch($query: String!) {
    internalUsersSearch(query: $query) {
      id
      email
      firstName
      lastName
      fullName
    }
  }
`;
