import {useContext} from 'react';

import {useApolloClient} from '@apollo/client';

import {
  JWT_KEY,
  deleteCookie,
  deleteAllCookies,
  REFRESH_TOKEN_KEY,
  getCookie,
} from '../cookies';
import logger from '../logger';
import logoutWithRedirect, {LOGIN_URL} from '../logoutWithRedirect';
import {AUTH_TOKEN, REFRESH_TOKEN, Context as StorageContext} from '../storage';
import {isDeployPreviewUrl} from '../netlify';
import {isCloudflareJwt, isCloudflareSession} from '../cloudflare';
import jwtDecode from '../jwtDecode';

export default function useLogout() {
  const client = useApolloClient();
  const storage = useContext(StorageContext);

  const logout = (params = {}) => {
    const hasCloudflareSessionAndJwt =
      isCloudflareSession() && isCloudflareJwt();

    try {
      client?.clearStore();
    } catch (err) {
      logger.error(err);
    }

    storage?.removeItem(AUTH_TOKEN);
    storage?.removeItem(REFRESH_TOKEN);
    // Delete cookie
    deleteCookie(JWT_KEY);
    deleteCookie(REFRESH_TOKEN_KEY);
    // Also delete all cookies for Netlify preview
    if (isDeployPreviewUrl()) {
      deleteAllCookies();
    }

    if (hasCloudflareSessionAndJwt) {
      window.location.href = `https://renofi.cloudflareaccess.com/cdn-cgi/access/logout`;
      return;
    }
    // logout of current app, including the redirect
    logoutWithRedirect(params);
  };

  return {logout};
}
