import React, {Fragment, useEffect, useRef, useState} from 'react';

import PropTypes from 'prop-types';

import {useLoanApplication, useProjectsQuickSearch} from '@renofi/graphql';
import {formatDate} from '@renofi/utilities/src/dates';
import {useSearchParam} from '@renofi/utilities';
import useDebounce from '@renofi/utilities/src/useDebounce';
import {basic55, Provider as ThemeProvider} from '@renofi/theme/src';
import humanizeSnakeCase from '@renofi/utilities/src/humanizeSnakeCase';

import {Box, Loader, Portal, Text, Tooltip} from '../';

import {
  Popover,
  Project,
  SearchField,
  StatusBadge,
  LoaderWrapper,
  POPOVER_WIDTH,
} from './styled';

function BorrowersSearch({
  inline = false,
  onSelectProject,
  searchByRuId,
  theme = 'dark',
  width,
}) {
  const [active, setActive] = useState(false);
  const [value, setValue] = useState('');
  const [projects, setProjects] = useState([]);
  const [loanApplication, setLoanApplication] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);
  const ref = useRef();
  const ResultsWrapper = inline ? Box : Portal;

  const {
    loading,
    fetch,
    projects: gqlProjects,
  } = useProjectsQuickSearch({lazy: true, fetchPolicy: 'no-cache'});
  const hasProjects = Boolean(gqlProjects.length);

  const {
    loading: loadingApps,
    fetch: fetchApps,
    lenderLoanApplication,
  } = useLoanApplication({lazy: true, fetchPolicy: 'no-cache'});

  const debouncedProjectsSearch = useDebounce((qQuery) => {
    fetch({variables: {limit: 15, query: qQuery}});
    fetchApps({variables: {id: qQuery}});
  }, 300);

  const debouncedSetActive = useDebounce((isActive) => {
    setActive(isActive);
  }, 300);

  useEffect(() => {
    setProjects(gqlProjects);
    setLoanApplication(lenderLoanApplication);

    const [firstProject] = gqlProjects || [];
    if (!firstProject && !lenderLoanApplication) {
      return;
    }

    setCurrentIndex(0);
    setActive(true);
  }, [loading, loadingApps]);

  const onChange = (inputValue) => {
    setValue(inputValue);
    debouncedProjectsSearch(inputValue);
  };

  useSearchParam(onChange);

  const onSelectLoanApp = () => {
    setActive(false);
    setProjects([]);
    setLoanApplication(null);
    setValue('');
    onSelectProject(loanApplication.projectId, loanApplication);
  };
  const onClickProject = (project = {}) => {
    const {
      renovationUnderwritingRequestId,
      renovationReadyFile,
      id: projectId,
    } = project || {};
    const isRrfReady = renovationReadyFile?.id;

    if (projectId) {
      const id =
        searchByRuId && !isRrfReady
          ? renovationUnderwritingRequestId
          : projectId;

      setActive(false);
      setProjects([]);
      setLoanApplication(null);
      setValue('');
      onSelectProject(id, project);
    }
  };

  const onKeyUp = (event) => {
    if (!hasProjects) {
      return;
    }

    if (event.key === 'Enter') {
      return onClickProject(projects[currentIndex]);
    }

    setActive(true);
    if (event.key === 'ArrowDown') {
      const nextIndex =
        projects.length - 1 > currentIndex ? currentIndex + 1 : 0;
      setCurrentIndex(nextIndex);
    } else if (event.key === 'ArrowUp') {
      const prevIndex =
        currentIndex > 0 ? currentIndex - 1 : projects.length - 1;
      setCurrentIndex(prevIndex);
    }
  };

  return (
    <Fragment>
      <Box mr={[0, 4]} alignItems="center" css={{position: 'relative'}}>
        <ThemeProvider theme={theme}>
          <div id="borrowers-search-input">
            <Tooltip
              place="left"
              content="Search by borrower name, email, renofiID or Lender loan number">
              <div ref={ref}>
                <SearchField
                  width={width}
                  active
                  leftIcon="glass"
                  mb={0}
                  onFocus={() => setActive(true)}
                  onBlur={() => debouncedSetActive(false)}
                  onKeyUp={onKeyUp}
                  onChange={onChange}
                  placeholder="Search borrowers"
                  value={value}
                />
              </div>
            </Tooltip>
            <LoaderWrapper>
              {loading || loadingApps ? (
                <Loader width={12} label={false} />
              ) : null}
            </LoaderWrapper>
          </div>
        </ThemeProvider>
        {active && (hasProjects || loanApplication?.id) && (
          <ResultsWrapper
            ref={ref}
            position="bottom-right"
            forceWidth={POPOVER_WIDTH}>
            <Popover inline={inline}>
              {loanApplication?.id && (
                <Project
                  active
                  css={{lineHeight: '20px'}}
                  p={3}
                  justifyContent="space-between"
                  key={loanApplication.id}
                  onClick={onSelectLoanApp}>
                  <Box
                    flexDirection="column"
                    className="borrower-search-item"
                    css={{maxWidth: '65%', overflow: 'hidden'}}>
                    <Text fontSize={14} fontWeight={700}>
                      Loan {loanApplication.loanNumber}
                    </Text>
                  </Box>
                  <Box css={{textAlign: 'right'}} flexDirection="column">
                    {loanApplication.appliedOn && (
                      <Text fontSize={12} fontWeight={700}>
                        Applied on {formatDate(loanApplication.appliedOn)}
                      </Text>
                    )}
                    <Text fontSize={12}>
                      {loanApplication.loanProduct?.name}
                    </Text>
                  </Box>
                </Project>
              )}
              {!loanApplication?.id &&
                projects.map((project, i) => {
                  const {renovationReadyFile} = project || {};
                  const isRrfReady = renovationReadyFile?.id;

                  return (
                    <Project
                      active={currentIndex === i}
                      css={{lineHeight: '20px'}}
                      p={3}
                      justifyContent="space-between"
                      key={project.id}
                      onClick={() => onClickProject(project)}>
                      <Box
                        flexDirection="column"
                        className="borrower-search-item"
                        css={{maxWidth: '65%', overflow: 'hidden'}}>
                        <Text fontSize={14} fontWeight={700}>
                          {project?.primaryApplicant?.fullName}
                        </Text>
                        <Text fontSize={12}>
                          {project?.streetAddressOne}, {project?.city},{' '}
                          {project?.state}
                        </Text>
                        <Text color={basic55} fontSize={12}>
                          {project?.primaryApplicant?.email}
                        </Text>
                      </Box>

                      <Box css={{textAlign: 'right'}} flexDirection="column">
                        {searchByRuId && !isRrfReady ? (
                          <StatusBadge small variant="warning">
                            PENDING
                          </StatusBadge>
                        ) : (
                          <StatusBadge
                            small
                            variant="default"
                            closed={['closed', 'closed_with_lender'].includes(
                              project.status,
                            )}>
                            {humanizeSnakeCase(project.stageName)}
                          </StatusBadge>
                        )}

                        <Text my={1} fontSize={12}>
                          {project.renofiId}
                        </Text>
                        <Text fontSize={12}>
                          {project?.loanTypeEnum?.crmName}
                        </Text>
                      </Box>
                    </Project>
                  );
                })}
            </Popover>
          </ResultsWrapper>
        )}
      </Box>
    </Fragment>
  );
}

BorrowersSearch.propTypes = {
  inline: PropTypes.bool,
  onSelectProject: PropTypes.func.isRequired,
  theme: PropTypes.string,
  width: PropTypes.number,
  searchByRuId: PropTypes.bool,
};

export default BorrowersSearch;
