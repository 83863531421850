import React, {useContext, useState} from 'react';

import PropTypes from 'prop-types';

import {
  Button,
  Flex,
  Text,
  Snackbar,
  SelectField,
  Box,
} from '@renofi/components-internal';
import {Provider as ThemeProvider} from '@renofi/theme/src';
import {useNotifications} from '@renofi/utilities/src/hooks';
import {useAssignDocumentToTaskDataSet} from '@renofi/graphql';

import UnassignedDocumentsContext from './context';
import {getTargetOptionsByTaskDataSetType} from './utils';

const MoveDocumentsStickyFooter = ({
  entityId,
  selectedDocIds = [],
  onClickCancel,
}) => {
  const {addNotification} = useNotifications();
  const {refetchQueries, taskDataSetType, task} = useContext(
    UnassignedDocumentsContext,
  );
  const [taskDataSetId, setTaskDataSetId] = useState(null);
  const {assignDocumentToTaskDataSet, loading} = useAssignDocumentToTaskDataSet(
    {refetchQueries},
  );

  const hasMany = selectedDocIds?.length > 1;
  const noun = hasMany ? 'documents' : 'document';
  const pronoun = hasMany ? 'these' : 'this';

  const options = getTargetOptionsByTaskDataSetType({
    entityId,
    taskDataSetType,
    task,
  });

  const onClickSubmit = async () => {
    const responses = await Promise.all(
      selectedDocIds.map((id) => {
        return assignDocumentToTaskDataSet({
          variables: {
            taskDataSetType,
            taskDataSetId,
            documentId: id,
            taskId: task?.id,
          },
        });
      }),
    );

    const hasErrors = responses.some((r) => Boolean(r.errors));
    if (!hasErrors) {
      addNotification({
        content: 'Documents moved',
        type: 'snackbar',
        variant: 'success',
      });

      onClickCancel();
    }
  };

  return (
    <ThemeProvider theme="dark">
      <Snackbar
        center
        closable
        width={800}
        variant="warning"
        message={
          <Flex pr={4} width={1} alignItems="center">
            <Text mr={2}>
              Move {pronoun} {selectedDocIds.length} {noun} to:
            </Text>
            <SelectField
              mb={0}
              onChange={setTaskDataSetId}
              options={options}
              placeholder="Select a destination"
              optionsCss={{top: null, bottom: 40}}
              position="top"
              small
              value={taskDataSetId}
              width={275}
            />
            <Box ml="auto">
              <Button
                disabled={!taskDataSetId || loading}
                loading={loading}
                onClick={onClickSubmit}
                variant="primary">
                Move {noun}
              </Button>
            </Box>
          </Flex>
        }
        onClose={onClickCancel}
      />
    </ThemeProvider>
  );
};

MoveDocumentsStickyFooter.propTypes = {
  entityId: PropTypes.string,
  selectedDocIds: PropTypes.arrayOf(PropTypes.string),
  onClickCancel: PropTypes.func.isRequired,
};

export default MoveDocumentsStickyFooter;
