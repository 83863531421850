import React, {useCallback, useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import {isEmpty, keyBy, mapValues} from 'lodash';

import {useEnums} from '@renofi/graphql';
import {
  Badge,
  DropZone,
  Flex,
  FileCard,
  SelectField,
} from '@renofi/components-internal';

function UploadFiles({
  onChange,
  showDocumentType = true,
  allDocumentTypes,
  documentTypeCss,
  showDocumentTypeForEachFile,
  compact,
  smallFileIcons,
  message,
  buttonLabel,
  dropZoneHeight,
  children,
  customDocumentType,
}) {
  const {documentTypes} = useEnums();
  const activeTypes = documentTypes?.filter((type) => type?.active);

  const [documentType, setDocumentType] = useState(customDocumentType || null);

  const [inlineDocumentTypes, setInlineDocumentTypes] = useState([]);
  const [validDocTypes, setValidDocTypes] = useState([]);
  const [files, setFiles] = useState([]);
  const [duplicateFiles, setDuplicateFiles] = useState([]);
  const [rejectedFiles, setRejectedFiles] = useState([]);
  const hasFiles = !isEmpty(files);

  // if facet selected, only show documentTypes for that facet.
  useEffect(() => {
    setValidDocTypes(mapValues(keyBy(activeTypes, 'value'), 'label'));
  }, [documentTypes?.length]);

  useEffect(() => {
    onChange({documentType, files, inlineDocumentTypes});
  }, [documentType, files, inlineDocumentTypes?.length]);

  const onInlineDocumentTypeChange = (type, file) => {
    setInlineDocumentTypes([
      ...inlineDocumentTypes.filter((type) => type.name !== file.name),
      {name: file.name, documentType: type},
    ]);
  };

  const onAcceptFiles = useCallback((newFiles) => {
    const fileNames = files.map((f) => f.name);
    const duplicates = [];
    const uniqFiles = newFiles.reduce((a, f) => {
      if (!fileNames.includes(f.name)) {
        return a.concat(f);
      }
      duplicates.push(f);
      return a;
    }, []);

    setFiles(files.concat(uniqFiles));
    setDuplicateFiles(duplicates);
    setRejectedFiles([]);
  });

  const onRejectFiles = useCallback((rejected) => {
    const newFiles = rejected.map((r) => r.file);
    setRejectedFiles(newFiles);
  });

  const onDeleteFile = useCallback((file) => {
    setFiles(files.filter((f) => f !== file));
  });

  return (
    <Flex flexDirection="column">
      {!documentType && showDocumentType && !showDocumentTypeForEachFile ? (
        <SelectField
          searchable
          label="Document type"
          value={documentType}
          onChange={setDocumentType}
          placeholder="Choose type"
          options={validDocTypes}
          containerCss={documentTypeCss}
        />
      ) : null}

      <DropZone
        message={message}
        compact={compact || hasFiles}
        onAcceptFiles={onAcceptFiles}
        onRejectFiles={onRejectFiles}
        buttonLabel={buttonLabel}
        extra={children}
        height={dropZoneHeight}
      />

      {!isEmpty(duplicateFiles) && (
        <Flex mb={16} flexWrap="wrap">
          You already added:
          {duplicateFiles.map((f) => (
            <Badge ml={2} variant="info" key={f.name}>
              {f.name}
            </Badge>
          ))}
        </Flex>
      )}

      {!isEmpty(rejectedFiles) && (
        <Flex mb={16} flexWrap="wrap">
          These files were invalid:
          {rejectedFiles.map((f) => (
            <Badge ml={2} variant="warning" key={f.name}>
              {f.name}
            </Badge>
          ))}
        </Flex>
      )}

      {hasFiles &&
        files.map((file) => (
          <FileCard
            documentType={
              showDocumentType && showDocumentTypeForEachFile && hasFiles ? (
                <SelectField
                  searchable
                  mb={16}
                  width={260}
                  optionsWidth={320}
                  label="Document type"
                  value={
                    inlineDocumentTypes?.find((type) => file.name === type.name)
                      ?.documentType
                  }
                  onChange={(value) => onInlineDocumentTypeChange(value, file)}
                  placeholder="Choose type"
                  options={allDocumentTypes ? activeTypes : validDocTypes}
                  containerCss={documentTypeCss}
                />
              ) : null
            }
            small={smallFileIcons}
            key={file.name}
            name={file?.name}
            onDelete={() => onDeleteFile(file)}
          />
        ))}
    </Flex>
  );
}

UploadFiles.propTypes = {
  facet: PropTypes.string,
  showDocumentType: PropTypes.bool,
  showDocumentTypeForEachFile: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  compact: PropTypes.bool,
  smallFileIcons: PropTypes.bool,
  message: PropTypes.string,
  buttonLabel: PropTypes.string,
  dropZoneHeight: PropTypes.number,
  documentTypeCss: PropTypes.object,
  children: PropTypes.node,
  allDocumentTypes: PropTypes.bool,
};

export default UploadFiles;
