import React from 'react';

import PropTypes from 'prop-types';

import {useTheme} from '@renofi/theme/src';

import Flex from '../Flex';
import Box from '../Box';

import {Badge} from './styled';

const BadgeWrapper = ({children, strong, suffix, uppercase, ...props}) => {
  const {styles} = useTheme('badge', props);

  const childrenElement = strong ? (
    <Box as="span" css={{fontFamily: 'Averta-Bold'}}>
      {children}
    </Box>
  ) : (
    children
  );

  return (
    <Badge
      px={1}
      py={1}
      {...props}
      theme={styles}
      css={{textTransform: uppercase ? 'uppercase' : 'inherit'}}>
      {Boolean(suffix) ? (
        <Flex height="100%" css={{gap: 8}} alignItems="center">
          {childrenElement}
          {suffix}
        </Flex>
      ) : (
        childrenElement
      )}
    </Badge>
  );
};

BadgeWrapper.propTypes = {
  bold: PropTypes.bool,
  strong: PropTypes.bool,
  children: PropTypes.node,
  pill: PropTypes.bool,
  rounded: PropTypes.bool,
  small: PropTypes.bool,
  uppercase: PropTypes.bool,
  suffix: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.string,
  ]),
  variant: PropTypes.string,
};

export default BadgeWrapper;
