import {gql} from '@apollo/client';

export default gql`
  query lenderLoanApplication($renovationReadyFileId: ID, $id: ID) {
    lenderLoanApplication(
      renovationReadyFileId: $renovationReadyFileId
      id: $id
    ) {
      id
      projectId
      appliedOn
      approvedOn
      closedOn
      conditions
      createdAt
      deniedOn
      estimatedClosingOn
      finalCreditScore
      finalDtiRatio
      finalLoanAmount
      finalLoanRate
      primaryDenialReason
      primaryDenialReasonExplanation
      secondaryDenialReason
      secondaryDenialReasonExplanation
      lender {
        id
        loanProducts {
          id
          name
        }
      }
      lenderProvidedIncome
      lenderProvidedMonthlyDebt
      lenderVerifiedReserves
      loanNumber
      loanProduct {
        id
        createdAt
        name
        updatedAt
      }
      renovationReadyFileId
      status
      updatedAt
      withdrawnExplanation
      withdrawnOn
      withdrawnReason
    }
  }
`;
