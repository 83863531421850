export default `
id
adjustedCost
cost
descriptionOfWork
reviewerNotes
reviewerRating
source
type
`;
