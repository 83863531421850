import React, {useEffect, useRef, useState} from 'react';

import {findIndex, isNil, propEq} from 'ramda';
import PropTypes from 'prop-types';

import {noop} from '@renofi/utilities';

import CommonFieldFrame from '../FieldFrame';
import Link from '../Link';
import Flex from '../Flex';
import ClickOutside from '../ClickOutside';
import TextField from '../TextField';
import Box from '../Box';

import {FakeTabIndex} from './styled';
import Options from './components/Options';
import OptionsContainer from './components/OptionsContainer';
import FieldFrame from './components/FieldFrame';
import {
  convertOptions,
  filterOptionsByInput,
  getFlattenedOptions,
} from './utils';

const commonValuePropTypes = PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.string,
  PropTypes.bool,
  PropTypes.object,
  PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.object,
      PropTypes.string,
      PropTypes.bool,
    ]),
  ),
]);

const SelectField = (props) => {
  const {
    disabled,
    fake,
    multi,
    name,
    onChange = noop,
    onOpen: propsOnOpen = noop,
    onToggle = noop,
    open: forceOpen = false,
    options,
    searchable,
    tabIndex,
    triggerComponent: Trigger = FieldFrame,
    triggerClickClose,
    value,
    width,
    optionsWidth,
    gridColumn,
    customOptions,
    customOptionComponent,
    selectAll = false,
  } = props;
  const [open, setOpen] = useState(forceOpen);

  const [lastKeyUp, setLastKeyUp] = useState(Date.now());
  const [filterInput, setFilterInput] = useState('');
  const [focusIndex, setFocusIndex] = useState(null);
  const ref = useRef();
  const clickOutsideRef = useRef(null);

  const optionsAsArray = customOptions?.length
    ? customOptions
    : convertOptions(options);
  const flattenedOptions = getFlattenedOptions(optionsAsArray);
  const filteredOptions =
    searchable && filterInput
      ? filterOptionsByInput(flattenedOptions, filterInput)
      : flattenedOptions;

  const empty = !filteredOptions?.length;
  const isInactive = disabled || empty || fake;

  useEffect(() => {
    if (open && ref?.current) {
      ref?.current?.focus();
    }
  }, [open]);

  const onKeyUp = (e) => {
    const totalOptions = filteredOptions?.length || 0;

    if (disabled || !filteredOptions?.length) {
      return;
    }

    switch (true) {
      case e.key === 'ArrowDown' && isNil(focusIndex):
        setFocusIndex(0);
        return setLastKeyUp(Date.now());
      case e.key === 'ArrowDown':
        setFocusIndex((state) =>
          state < totalOptions - 1 ? state + 1 : state,
        );
        return setLastKeyUp(Date.now());

      case e.key === 'ArrowUp' && isNil(focusIndex):
        setFocusIndex(totalOptions - 1);
        return setLastKeyUp(Date.now());
      case e.key === 'ArrowUp':
        setFocusIndex((state) => (state > 0 ? state - 1 : state));
        return setLastKeyUp(Date.now());

      case e.key === 'Enter':
        const value = filteredOptions?.[focusIndex]?.value;
        setLastKeyUp(Date.now());
        return onSelect(value, e);
      default:
        return setLastKeyUp(Date.now());
    }
  };

  const onOpen = (isOpen) => {
    propsOnOpen();
    setOpen(isOpen);
    onToggle(isOpen);

    const index = findIndex(propEq('value', value), filteredOptions);
    setFocusIndex(index === -1 ? null : index);
  };

  const onClose = () => {
    setOpen(false);
    onToggle(false);
  };

  const onSelect = (option, event) => {
    event?.stopPropagation();

    if (multi && Array.isArray(value)) {
      const newValues = value.includes(option)
        ? value.filter((val) => val !== option)
        : value.concat(option);
      const filtered = newValues.filter((v) => !isNil(v));

      onChange(filtered, value);
    } else {
      onChange(option, value);
    }

    setFocusIndex(null);
    !multi && onClose();
  };

  const onWrapperClick = (event) => {
    event?.stopPropagation();
    if (!isInactive) {
      onOpen(true);
    }
  };

  const onSelectAllValues = (e) => {
    e.preventDefault();
    onChange(
      filteredOptions.map((o) => o.value),
      value,
    );
  };

  const onClearAllValues = (e) => {
    e.preventDefault();
    onChange([], value);
  };

  const onSearchChange = (input) => {
    setFilterInput(input);
  };

  return (
    <ClickOutside ref={clickOutsideRef} onClickOutside={onClose}>
      <div
        onClick={onWrapperClick}
        style={{
          width: width ? width : 'auto',
          gridColumn,
          position: 'relative',
        }}>
        <FakeTabIndex
          name={name}
          onBlur={() => {
            if (!searchable) {
              onClose();
            }
          }}
          onChange={() => {}}
          onFocus={onClose}
          tabIndex={tabIndex}
          type="text"
          value={value}
        />

        {searchable && open ? (
          <TextField
            {...props}
            leftIcon={props.searchIcon ? 'glass' : null}
            onChange={onSearchChange}
            onKeyUp={onKeyUp}
            placeholder={props.searchPlaceholder || ''}
            forwardedRef={ref}
            tabIndex={tabIndex}
            value={filterInput}
          />
        ) : (
          <Box
            onClick={(event) => {
              if (open && triggerClickClose) {
                event?.stopPropagation();
                onClose();
              }
            }}>
            <Trigger
              ref={ref}
              {...props}
              options={filteredOptions}
              open={open}
            />
          </Box>
        )}

        {open && (
          <OptionsContainer
            filterInput={filterInput}
            lastKeyUp={lastKeyUp}
            multi={multi}
            onClose={onClose}
            onKeyUp={onKeyUp}
            options={filteredOptions}
            tabIndex={tabIndex}
            value={value}
            width={optionsWidth}
            {...props}>
            {multi && selectAll ? (
              <Flex css={{gap: 16}} px={3} py="12px">
                <Link css={{fontSize: 12}} dark onClick={onSelectAllValues}>
                  Select all
                </Link>
                <Link css={{fontSize: 12}} dark onClick={onClearAllValues}>
                  Clear
                </Link>
              </Flex>
            ) : null}

            <Options
              {...props}
              multi={multi}
              value={value}
              options={filteredOptions}
              focusIndex={focusIndex}
              onClose={onClose}
              onSelect={onSelect}
              customOptionComponent={customOptionComponent}
            />
          </OptionsContainer>
        )}
      </div>
    </ClickOutside>
  );
};

SelectField.propTypes = {
  ...CommonFieldFrame.propTypes,
  onChange: PropTypes.func,
  onOpen: PropTypes.func,
  open: PropTypes.bool,
  options: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  position: PropTypes.string,
  searchIcon: PropTypes.bool,
  triggerComponent: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.node,
    PropTypes.string,
  ]),
  value: commonValuePropTypes,
};

export default SelectField;
