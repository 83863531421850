import React, {useState} from 'react';

import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';

import SideMenuIcon from '@renofi/icons/src/SideMenu';
import {Flex, SelectField} from '@renofi/components-internal';
import {canManageInternalUsers} from '@renofi/utilities/src/permissions';
import {Arrow} from '@renofi/icons';
import PersonIcon from '@renofi/icons/src/Person';
import {useScreenSize} from '@renofi/utilities';

import {myAccount} from '../../../../routes';

const Menu = ({logout, user, email, allowedPaths}) => {
  const {isMobile} = useScreenSize();
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();

  const onToggle = (value) => {
    setIsOpen(value);
  };

  const Person = () => (
    <Flex alignItems="center" css={{gap: '8px', cursor: 'pointer'}}>
      <Arrow direction={isOpen ? 'up' : 'down'} />
      <PersonIcon width={20} />
    </Flex>
  );

  return (
    <SelectField
      onToggle={onToggle}
      right
      small
      value={email}
      optionsCss={{zIndex: 10009}}
      triggerComponent={isMobile ? SideMenuIcon : Person}
      triggerClickClose
      options={[
        {
          label: email,
          value: 'email',
          disabled: true,
        },
        {
          label: 'My account',
          value: 'account',
          onClick: () => history.push(myAccount()),
        },
        ...(isMobile
          ? allowedPaths.map((data) => {
              return {
                label: data.label,
                value: data.path,
                onClick: () => history.push(data.path),
              };
            })
          : []),
        ...(canManageInternalUsers(user)
          ? [
              {
                label: 'Users',
                value: 'users',
                onClick: () => history.push('/users'),
              },
            ]
          : []),
        {
          label: 'Sign out',
          value: 'logout',
          onClick: logout,
        },
      ]}
    />
  );
};

Menu.propTypes = {
  logout: PropTypes.func.isRequired,
};

export default Menu;
