import {getItem} from '@renofi/utils';
import {isTestSession, sendEvent} from '@renofi/analytics';

import {getLocation} from './window';
import {getCookie, JWT_KEY} from './cookies';
import jwtDecode from './jwtDecode';

// Exchange local CF cookie jwt for Chainlink jwt
export const getAuthToken = async (url) => {
  sendEvent('Blueprint/CF-Session-Fetch');
  let response;

  try {
    response = await fetch(url, {
      method: 'GET',
      credentials: 'include',
    });
  } catch (error) {
    sendEvent('Blueprint/CF-Session-Fetch-Failed');
    redirect(url);
    return;
  }

  if (!response?.ok) {
    redirect(url);
    sendEvent('Blueprint/CF-Session-Fetch-Failed');
    return;
  }

  if (!response?.ok) {
    redirect(url);
    return;
  }

  const data = await response?.json();

  if (data?.jwt) {
    sendEvent('Blueprint/CF-Session-Fetch-Success');
  }

  return {
    jwt: data.jwt,
    refreshToken: data.refresh_token,
  };
};

export const redirect = (url) => {
  getLocation().replace(`${url}?redirectUrl=${getLocation().href}`);
};

export const isCloudflareSession = () =>
  !isTestSession() &&
  getItem('CF_Authorization')?.length > 0 &&
  getLocation().host.includes('blueprint');

export const isCloudflareJwt = () => {
  const cookieJwt = getCookie(JWT_KEY);
  const decodedJwt = jwtDecode(cookieJwt);
  return Boolean(decodedJwt?.cf_email);
};
