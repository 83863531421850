import React, {forwardRef} from 'react';

import PropTypes from 'prop-types';
import {isString} from 'lodash';

import {useTheme} from '@renofi/theme/src';

import Box from '../../../Box';
import {fieldHeight} from '../../../FieldFrame/styled';

const CSS = {
  borderRadius: 4,
  transition: 'all 500ms ease',
  maxHeight: 360,
  overflow: 'hidden',
  overflowY: 'auto',
};

const OptionsContainer = forwardRef(
  (
    {
      children,
      containerCss,
      onClose,
      optionsWidth,
      optionsCss = {},
      label,
      large,
      small,
      right,
      ml,
    },
    parentRef,
  ) => {
    const {styles} = useTheme('options');

    const labelHeight = label ? (small ? 17 : 20) : 0;
    const labelMargin = label ? (small ? 4 : 6) : 0;
    const marginLeft = isString(ml) ? Number(ml.replace('px', '')) : ml || 0;

    return (
      <Box
        ref={parentRef}
        forceWidth={optionsWidth}
        styles={{minWidth: parentRef?.current?.offsetWidth}}
        onClickOutside={onClose}
        css={{
          position: 'absolute',
          top: labelMargin + fieldHeight({small, large}) + labelHeight,
          width: 'auto',
          minWidth: '100%',
          zIndex: 2,
          ...(right ? {right: 0} : {left: marginLeft}),
          ...optionsCss,
        }}>
        <Box
          data-testid="options-container"
          as="div"
          css={{
            ...styles,
            ...CSS,
            ...containerCss,
          }}>
          {children}
        </Box>
      </Box>
    );
  },
);

OptionsContainer.propTypes = {
  children: PropTypes.node,
  containerCss: PropTypes.object,
  optionsCss: PropTypes.object,
  help: PropTypes.node,
  label: PropTypes.node,
  position: PropTypes.string,
  optionsWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClose: PropTypes.func.isRequired,
  onKeyUp: PropTypes.func.isRequired,
  onSearchBy: PropTypes.func.isRequired,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  searchable: PropTypes.bool,
  filterInput: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  tabIndex: PropTypes.number,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.object,
    PropTypes.string,
    PropTypes.bool,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    ),
  ]),
  searchIcon: PropTypes.bool,
};

OptionsContainer.defaultProps = {
  containerCss: {},
  position: 'bottom',
};

export default OptionsContainer;
